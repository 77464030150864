import React, { Component } from 'react'

import '@babylonjs/loaders';
import '@babylonjs/core/Debug/debugLayer'
import '@babylonjs/inspector'

import * as BABYLON from "@babylonjs/core";
import * as GUI from '@babylonjs/gui'

import SceneComponent from "./SceneComponent"; // uses above component in same directory
import "./App.scss";

const toBoolean = (value) => value === 'true';

const debug = toBoolean(process.env.REACT_APP_DEBUG)
const hasPanel = toBoolean(process.env.REACT_APP_HAS_PANEL)
const mobile = toBoolean(process.env.REACT_APP_MOBILE)
const isObjRotating = toBoolean(process.env.REACT_APP_IS_ROTATING)
const baseUrl = process.env.REACT_APP_BASE_URL
const asset = new URLSearchParams(window.location.search)?.get('assetUrl');
const assetUrl = baseUrl + asset

let scene;
let arrLights = [];
let arrElements = [];

/** Rotate asset */
const CONFIG_ROTATE_ASSET = isObjRotating;
const currentPosition = { x: 0, y: 0 };
let clicked = false;
let meshmodel;

let divFps;
let gui;
const posLights = [[0.8, 7, 5], [5.7, 1.6, 2.5], [-6.6, 3.1, 0.6]]
const arrShadows = [true, false, false]
const typeLights = ["SpotLight", "SpotLight", "SpotLight"]
const arrIntensities = [80, 25, 20]
/**
 * 1. From Charles
 * 2. Scanroom
 * 3. Holodeck
 * 4. Babylon Sandbox
*/
const arrEnv = ["./env/studio2.env", "./env/studio_prod.env", "./env/ScanRoom_06_Thumbnail.hdr", "./env/0a671733f5c9649d593dca478b173f26.hdr", "./env/studio.env"]

let arrShadowGens = []
let arrGizmo = []
/**
 * 1. Lady Dior
 * 2. Saddle
 * 3. Vanity
 * 4. Sneaker
 * 5. Porte-cartes
 * 6. Wallet
 * 7. Car (sheen)
 * 8. Amber (IOR)
 */
let sphere = null;
let hasDebugLayer = false;
let rContainer;
let GLBhasLights = false
let isSliderMoving = false
let defaultCamTarget = null

// ANIM
const FRAMES_PER_SECOND = 60
const SPEED_RATIO = 2;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

let globalAngleX = 0
let globalAngleY = 0
class App extends Component {

  state = {
    currentModel: 0,
    currentEnv: 0,
    isError: false
  }

  onSceneReady = (tScene) => {
    scene = tScene;

    scene.clearColor = new BABYLON.Color3(0.95, 0.95, 0.95);
    scene.getEngine().setHardwareScalingLevel(1 / window.devicePixelRatio);
    scene.getEngine().resize();

    // Scene optimizer
    const options = new BABYLON.SceneOptimizerOptions();
    options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1));
    new BABYLON.SceneOptimizer(scene, options);

    if (assetUrl) {
      this.addBag(assetUrl, true)
    }

    if (debug) divFps = document.getElementById("fps");
  }

  onError = () => {
    this.setState({ isError: true })
    this.showLoading(false)
    this.initCamera();
  }

  addLights = () => {
    for (let index = 0; index < posLights.length; index++) {
      let light
      if (typeLights[index] === 'SpotLight') {
        light = new BABYLON.SpotLight(`spotLight${index}`, new BABYLON.Vector3(posLights[index][0], posLights[index][1], posLights[index][2]), new BABYLON.Vector3(0, 0, 0), Math.PI / 2, 2, scene);
        if (index === 0) light.angle = Math.PI * 1.5
      } else if (typeLights[index] === 'PointLight') {
        light = new BABYLON.PointLight(`pointLight${index}`, new BABYLON.Vector3(posLights[index][0], posLights[index][1], posLights[index][2]), scene);
      } else if (typeLights[index] === 'DirectionalLight') {
        const meshmodel = scene.getMeshByName("assetContainerRootMesh")
        const bb = meshmodel.getBoundingInfo().boundingBox

        light = new BABYLON.DirectionalLight(`directionalLight${index}`, bb.centerWorld, scene);
        light.position = new BABYLON.Vector3(posLights[index][0], posLights[index][1], posLights[index][2])
      }
      light.intensity = arrIntensities[index];

      this.setLookAt(light)

      if (debug) this.createGizmo(light, 1)
      arrElements.push(light)
      arrLights.push(light)

      // Shadow
      this.createShadow(light, index)
    }
  }

  createGizmo = (customLight, intensity) => {
    const lightGizmo = new BABYLON.LightGizmo(new BABYLON.UtilityLayerRenderer(scene));
    lightGizmo.scaleRatio = 2.8 + intensity;
    lightGizmo.light = customLight;

    arrElements.push(lightGizmo)
    arrGizmo.push(lightGizmo)
  }

  handleModelLoaded = (container) => {
    this.setState({ isError: false });
    container.createRootMesh();
    container.addAllToScene();
    if (scene.isLoading) {
      scene.onDataLoadedObservable.addOnce(() => this.initCamera());
    } else {
      this.onError()
    }

    rContainer = container

    if (container.lights.length > 0) {
      GLBhasLights = true
    }
    // Convert intensity from blender
    container.lights.forEach(light => {
      const watts = light.intensity
      light.intensity = watts / 1000
    });

    // Optims scene
    // scene.freezeActiveMeshes()
    scene.skipPointerMovePicking = true
    scene.autoClearDepthAndStencil = false;
    scene.blockMaterialDirtyMechanism = true;

    // Shadow
    container.meshes.forEach(mesh => {
      mesh.receiveShadows = true
    });
  }

  addBag = (isFirst = false) => {
    GLBhasLights = false
    this.showLoading()

    if (assetUrl && isFirst) {
      BABYLON.SceneLoader.LoadAssetContainer('', assetUrl, scene, this.handleModelLoaded, null, this.onError);
    }
  }

  /** Detect if it is a mobile */
  isTouchDevice() {
    return ('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0);
  }

  /** Check if model is still visible when moved */
  checkIfVisible = () => {
    const meshmodel = scene.getMeshByName("assetContainerRootMesh")
    const isVisible = scene.activeCamera.isInFrustum(meshmodel)
    if (!isVisible) {
      // ANIM
      this.moveActiveCamera(scene, {
        target: new BABYLON.Vector3(defaultCamTarget.target.x, defaultCamTarget.target.y, defaultCamTarget.target.z),
      })

    }
  }

  initCamera = () => {
    scene.createDefaultCamera(true)

    const camera = scene.activeCamera;
    camera.alpha += Math.PI;
    // Enable camera's behaviors
    camera.useFramingBehavior = true;
    camera.lowerRadiusLimit = null;
    camera.upperRadiusLimit = 5 * camera.radius;
    camera.wheelDeltaPercentage = 0.01;
    camera.pinchDeltaPercentage = 0.001;

    // Rotate asset
    if (!CONFIG_ROTATE_ASSET) {
      camera.attachControl(true);
    } else {
      setTimeout(() => {
        camera.attachControl(true);
        // Add only event right button
        const isTouch = this.isTouchDevice()
        if (!isTouch) {
          camera.inputs.removeByType("ArcRotateCameraKeyboardMoveInput");
          camera.inputs.attached.pointers.buttons = [2]
          this.addRotationModel();
        } else {
          const canvas = document.querySelector('canvas')
          canvas.addEventListener("touchend", (evt) => {
            this.checkIfVisible()
          });
        }
      }, 500);
    }

    const worldExtends = scene.getWorldExtends(function (mesh) {
      return mesh.isVisible && mesh.isEnabled();
    });

    // Calculate real bounding box
    const root = scene.getMeshByName("assetContainerRootMesh")
    let min = null
    let max = null

    let childMeshes = root.getChildMeshes();

    childMeshes.forEach(mesh => {
      if (!min) min = mesh.getBoundingInfo().boundingBox.minimumWorld;
      if (!max) max = mesh.getBoundingInfo().boundingBox.maximumWorld;
      let meshMin = mesh.getBoundingInfo().boundingBox.minimumWorld;
      let meshMax = mesh.getBoundingInfo().boundingBox.maximumWorld;

      min = BABYLON.Vector3.Minimize(min, meshMin);
      max = BABYLON.Vector3.Maximize(max, meshMax);
    });

    root.setBoundingInfo(new BABYLON.BoundingInfo(min, max));
    // End real bounding

    if (debug) {
      const infos = document.querySelector('.infos')
      const wi = Math.ceil(root.getBoundingInfo().boundingBox.extendSize.x * 100)
      const he = Math.ceil(root.getBoundingInfo().boundingBox.extendSize.y * 100)
      const de = Math.ceil(root.getBoundingInfo().boundingBox.extendSize.z * 100)

      infos.innerHTML = `<br>${wi} x ${he} x ${de}`
    }

    // Framing
    const framingBehavior = camera.getBehaviorByName("Framing");
    framingBehavior.framingTime = 0;
    framingBehavior.elevationReturnTime = -1;
    framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);
    // Saves default camera target
    defaultCamTarget = { target: { x: camera.target.x, y: camera.target.y, z: camera.target.z } }

    // Debug
    // gScene.debugLayer.show({ embedMode:true });
    if (hasPanel && !hasDebugLayer) {
      scene.debugLayer.show({ embedMode: true });
      hasDebugLayer = true
    }

    if (!mobile) {
      this.addEnvironmentTexture()
    } else {
      this.addEnvironmentTexture(1)
    }
    if (debug) {
      this.addGroundNormal(worldExtends)
    }

    this.showLoading(false)
  }

  addGroundNormal = (worldExtends) => {
    const sizeGround = 1.5;
    const ground2 = BABYLON.MeshBuilder.CreateBox("ground2", { size: sizeGround }, scene, false)
    ground2.position.y = worldExtends.min._y + sizeGround / 2;
    ground2.material = new BABYLON.StandardMaterial("mat", scene);
    ground2.material.wireframe = true;

    arrElements.push(ground2)
  }

  addEnvironmentTexture = (nb = 0) => {
    const env = arrEnv[nb]
    let reflectionTexture
    if (env.includes('.hdr')) {
      // HDR
      reflectionTexture = new BABYLON.HDRCubeTexture(env, scene, 128, false, true, false, true);

      // Texture rotation
      const hdrRotation = 80; // in degrees
      reflectionTexture.setReflectionTextureMatrix(
        BABYLON.Matrix.RotationY(
          BABYLON.Tools.ToRadians(hdrRotation)
        )
      );

    } else {
      // ENV
      reflectionTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(env, scene);
      reflectionTexture.rotationY = 1.2
    }

    scene.environmentTexture = reflectionTexture
    scene.environmentIntensity = 1.2

    if (!GLBhasLights) this.addLights()
    if (debug) this.addGUI()
    this.setState({ currentEnv: nb })
    if (nb !== 0 && debug) {
      setTimeout(() => {
        this.addSphere()
      }, 1000);
    }
  }

  removeEnvironmentTexture() {
    const tmp = scene.environmentTexture
    tmp.dispose()
    scene.environmentIntensity = 0
    this.setState({ currentEnv: 5 })
  }

  addSphere = () => {
    sphere = BABYLON.MeshBuilder.CreateSphere("sphere1", { diameter: 0.6, segments: 32 }, scene);
    const sphere1Mat = new BABYLON.PBRMaterial("sphere1mat", scene);
    sphere1Mat.roughness = 0.15;
    sphere1Mat.metallic = 1;

    sphere.material = sphere1Mat;
    sphere.position.y = 0;
    sphere.position.z = -1;
  }

  /** Move model and not camera */
  addRotationModel = () => {
    const canvas = document.querySelector('canvas')

    meshmodel = scene.getMeshByName("assetContainerRootMesh")
    this.reset()
    if (!meshmodel) return

    canvas.addEventListener("pointerdown", function (evt) {
      currentPosition.x = evt.clientX;
      currentPosition.y = evt.clientY;
      if (evt.button === 0) clicked = true;
    });

    canvas.addEventListener("pointermove", function (evt) {
      if (isSliderMoving) return

      if (!clicked) {
        return;
      }

      const dx = evt.clientX - currentPosition.x;
      const dy = evt.clientY - currentPosition.y;

      const angleX = dy * 0.01;
      const angleY = -dx * 0.01;

      globalAngleX += angleX
      globalAngleY += angleY

      globalAngleX = Math.min(Math.max(globalAngleX, -1.55), 1.55);

      meshmodel.rotation = new BABYLON.Vector3(0, 0, 0);
      meshmodel.addRotation(globalAngleX, 0, 0);
      meshmodel.addRotation(0, globalAngleY, 0);

      currentPosition.x = evt.clientX;
      currentPosition.y = evt.clientY;
    });

    canvas.addEventListener("pointerup", (evt) => {
      clicked = false;
      this.checkIfVisible()
    });

  }

  /** Reset rotation button */
  reset() {
    meshmodel.rotation = new BABYLON.Vector3(0, 0, 0)
    globalAngleX = 0
    globalAngleY = 0
  }

  /**
  * Will run on every frame render.  We are spinning the box on y-axis.
  */
  onRender = (scene) => {
    if (divFps && debug) divFps.innerHTML = scene.getEngine().getFps().toFixed() + " fps";
  };

  clickBtModel = (nb = 0) => {
    this.unloadScene()
    this.setState({ currentModel: nb })
  }

  /** Destroy */
  unloadScene = () => {
    scene.meshes.forEach(mesh => {
      mesh.dispose();
      mesh = null;
    })
    scene.meshes = []
    if (rContainer) {
      rContainer.removeAllFromScene();
      rContainer = null
    }
    meshmodel = null
    scene.cameras.forEach(camera => {
      camera.dispose();
      camera = null;
    })
    scene.lights.forEach(light => {
      light.dispose();
      scene.removeLight(light)
      light = null;
    })
    scene.materials.forEach(material => {
      material.dispose();
      material = null;
    })
    arrElements.forEach(element => {
      element.dispose();
      scene.removeLight(element)
      element = null;
    })
    arrElements = []
    arrLights = []
    arrGizmo = []
    if (sphere) {
      sphere.dispose();
      sphere = null;
    }
    // Dispose shadow gen
    const root = scene.getMeshByName("assetContainerRootMesh")
    arrShadowGens.forEach(shadowGen => {
      shadowGen.removeShadowCaster(root)
      shadowGen.dispose()
      shadowGen = null
    });
    arrShadowGens = []
  }

  /** Drag and drop glb model */
  handleDrop = (files, path) => {
    this.unloadScene()

    const data = files[0]
    const blob = new Blob([data], { type: "model/gltf-binary" });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      GLBhasLights = false
      this.showLoading()

      BABYLON.SceneLoader.LoadAssetContainer("file:", reader.result, scene, this.handleModelLoaded, null, this.onError)
    }
  }

  /** Adds GUI */
  addGUI = () => {
    if (gui) return
    gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI", true);

    const panel = new GUI.StackPanel();
    panel.width = "300px";
    if (!hasPanel) {
      panel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    } else {
      panel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    }
    panel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    panel.paddingRight = 60;
    gui.addControl(panel);

    for (let index = 0; index < posLights.length; index++) {
      this.addTitle(panel, "LIGHT " + (index + 1))
      this.addSlider(panel, index, 'x', posLights[index][0], 0)
      this.addSlider(panel, index, 'y', posLights[index][1], 1)
      this.addSlider(panel, index, 'z', posLights[index][2], 2)
      this.addSliderIntensity(panel, index, arrIntensities[index])
      if (index === 0) this.addChoice(panel, index)
      this.addCheckbox(panel, index)
    }
  }

  /** GUI checkbox */
  addCheckbox(panel, index) {
    const sPanel = new GUI.StackPanel();
    sPanel.width = "200px";
    sPanel.height = '50px'
    sPanel.isVertical = false;
    sPanel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    sPanel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    panel.addControl(sPanel);

    const checkbox = new GUI.Checkbox();
    checkbox.width = "30px";
    checkbox.height = "30px";
    checkbox.isChecked = arrShadows[index];
    checkbox.color = "gold";
    checkbox.onIsCheckedChangedObservable.add((value) => {
      this.clickCheckbox(value, index)

    });
    sPanel.addControl(checkbox);

    const header = new GUI.TextBlock();
    header.text = "Diffuse shadow";
    header.width = "180px";
    header.onPointerDownObservable.add(() => {
      checkbox.isChecked = !checkbox.isChecked;
    });
    sPanel.addControl(header);
  }

  clickCheckbox(value, index) {
    arrShadows[index] = value
    arrLights[index].shadowEnabled = value
  }

  removeLight(name) {
    const firstLight = scene.getLightByName(name)
    scene.removeLight(firstLight)
    arrElements = arrElements.filter((element) => element.name !== name)
    arrLights = arrLights.filter((element) => element.name !== name)

    // Remove LightGizmo
    arrGizmo.forEach(gizmo => {
      if (gizmo._light.name === name) {
        gizmo.dispose()
      }
    });
  }

  changeLight(light) {
    if (light === 0) {
      // Remove PointLight
      this.removeLight('pointLight0')
      this.removeLight('directionalLight0')

      // Create spotLight
      const spotLight = new BABYLON.SpotLight('spotLight0', new BABYLON.Vector3(posLights[0][0], posLights[0][1], posLights[0][2]), new BABYLON.Vector3(0, 0, 0), Math.PI / 2, 2, scene);
      spotLight.intensity = arrIntensities[0];
      this.setLookAt(spotLight)
      if (debug) this.createGizmo(spotLight, 1)
      arrElements.unshift(spotLight)
      arrLights.unshift(spotLight)
      this.createShadow(spotLight, 0)

    } else if (light === 1) {
      // Remove SpotLight
      this.removeLight('spotLight0')
      this.removeLight('directionalLight0')

      // Create pointLight
      const pointLight = new BABYLON.PointLight('pointLight0', new BABYLON.Vector3(posLights[0][0], posLights[0][1], posLights[0][2]), scene);
      pointLight.intensity = arrIntensities[0];
      if (debug) this.createGizmo(pointLight, 1)
      arrElements.unshift(pointLight)
      arrLights.unshift(pointLight)
      this.createShadow(pointLight, 0)
    } else {

      // Remove SpotLight
      this.removeLight('spotLight0')
      this.removeLight('pointLight0')

      const meshmodel = scene.getMeshByName("assetContainerRootMesh")
      const bb = meshmodel.getBoundingInfo().boundingBox

      const directionalLight = new BABYLON.DirectionalLight('directionalLight0', bb.centerWorld, scene);
      directionalLight.position = new BABYLON.Vector3(posLights[0][0], posLights[0][1], posLights[0][2])

      directionalLight.intensity = arrIntensities[0];

      if (debug) this.createGizmo(directionalLight, 1)
      arrElements.unshift(directionalLight)
      arrLights.unshift(directionalLight)
      this.createShadow(directionalLight, 0)

    }
  }

  /** Self shadow */
  createShadow(light, index) {
    light.shadowEnabled = arrShadows[index]

    const shadowGen = new BABYLON.ShadowGenerator(2048, light)
    const root = scene.getMeshByName("assetContainerRootMesh")
    shadowGen.addShadowCaster(root)
    arrShadowGens.push(shadowGen)

    // Blur shadows
    shadowGen.useBlurCloseExponentialShadowMap = true
    light.shadowMinZ = 1
    light.shadowMaxZ = 50

    // Self shadow
    shadowGen.useBlurExponentialShadowMap = true;
    shadowGen.usePercentageCloserFiltering = true;

    // Stripes
    shadowGen.bias = 0.00001;
    shadowGen.forceBackFacesOnly = true;
  }

  addRadio(text, panel, index) {
    const button = new GUI.RadioButton();
    button.width = "40px";
    button.height = "40px";
    button.color = "white";
    button.background = "black";
    if (typeLights[0] === text) button.isChecked = true

    button.onIsCheckedChangedObservable.add((state) => {
      if (index === 0 && state) {
        this.changeLight(0)
        typeLights[0] = "SpotLight"
      } else if (index === 1 && state) {
        this.changeLight(1)
        typeLights[0] = "PointLight"
      } else if (index === 2 && state) {
        this.changeLight(2)
        typeLights[0] = "DirectionalLight"
      }
    });

    const header = GUI.Control.AddHeader(button, text, "200px", { isHorizontal: true, controlFirst: true });
    header.height = "50px";
    header.children[1].onPointerDownObservable.add(function () {
      if (!button.isChecked) button.isChecked = true;
    });

    panel.addControl(header);
  }

  addChoice(panel, index) {
    this.addRadio('SpotLight', panel, 0)
    this.addRadio('PointLight', panel, 1)
    this.addRadio('DirectionalLight', panel, 2)
  }

  /** GUI slider */
  addSlider(panel, num, prop, defaultPos, num2) {
    const defaultText = prop + ": "

    const header = new GUI.TextBlock();
    header.text = defaultText + " " + defaultPos;
    header.height = "30px";
    header.color = "black";
    panel.addControl(header);

    const slider = new GUI.Slider();
    slider.minimum = -10;
    slider.maximum = 10;
    slider.value = defaultPos;
    slider.height = "20px";
    slider.width = "200px";
    slider.step = 0.1;
    slider.onValueChangedObservable.add((value) => {
      isSliderMoving = true
      header.text = defaultText + Math.round(value * 100) / 100
      posLights[num][num2] = value
      if (GLBhasLights) return
      arrLights[num].position[prop] = value
      this.setLookAt(arrLights[num])
    });
    slider.onPointerUpObservable.add(function (value) {
      isSliderMoving = false
    });
    panel.addControl(slider);
  }

  addSliderIntensity(panel, num, defaultPos) {
    const defaultText = 'intensity : '

    const header = new GUI.TextBlock();
    header.text = defaultText + " " + defaultPos;
    header.height = "30px";
    header.color = "black";
    panel.addControl(header);

    const slider = new GUI.Slider();
    slider.minimum = 0;
    slider.maximum = 30;
    slider.value = arrIntensities[num];
    slider.height = "20px";
    slider.width = "200px";
    slider.color = 'gold';
    slider.step = 0.1;
    slider.onValueChangedObservable.add(function (value) {
      isSliderMoving = true
      header.text = defaultText + Math.round(value * 100) / 100
      arrIntensities[num] = value
      if (GLBhasLights) return
      arrLights[num].intensity = value
      arrGizmo[num].scaleRatio = 2.8 + value;
    });
    slider.onPointerUpObservable.add(function (value) {
      isSliderMoving = false
    });
    panel.addControl(slider);
  }

  addTitle(panel, text) {
    const textBlock = new GUI.TextBlock();
    textBlock.text = text;
    textBlock.color = "black";
    textBlock.outlineWidth = 50
    textBlock.height = '50px'
    panel.addControl(textBlock);
  }

  /** END GUI */

  setLookAt(light) {
    const meshmodel = scene.getMeshByName("assetContainerRootMesh")
    const bb = meshmodel.getBoundingInfo().boundingBox
    light.setDirectionToTarget(bb.centerWorld)
  }

  componentDidMount() {
    window.addEventListener("resize", function () {
      scene.getEngine().resize();
    });
  }

  /** Display / Hide Babylon loading */
  showLoading(value = true) {
    if (value) {
      scene.getEngine().displayLoadingUI();
      scene.getEngine().loadingUIBackgroundColor = "transparent"
      scene.getEngine().loadingUIText = "Loading..."
    } else {
      scene.getEngine().hideLoadingUI();
    }
  }

  /** Animation when model is out of sight */
  createAnimation({ property, from, to }) {
    const ease = new BABYLON.PowerEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

    const animation = BABYLON.Animation.CreateAnimation(
      property,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      FRAMES_PER_SECOND,
      ease
    );
    animation.setKeys([
      {
        frame: 0,
        value: from,
      },
      {
        frame: 100,
        value: to,
      },
    ]);

    return animation;
  }

  /** Move camera anim */
  moveActiveCamera(scene, {
    radius,
    alpha,
    beta,
    target: { x: targetX, y: targetY, z: targetZ },
  }) {
    const camera = scene.activeCamera;

    camera.animations = [
      this.createAnimation({
        property: "target.x",
        from: camera.target.x,
        to: targetX,
      }),
      this.createAnimation({
        property: "target.y",
        from: camera.target.y,
        to: targetY,
      }),
      this.createAnimation({
        property: "target.z",
        from: camera.target.z,
        to: targetZ,
      }),
    ];

    scene.beginAnimation(camera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO);
  }

  render() {
    return (
      <div style={{ display: 'inline-block', position: 'relative' }} className={this.state.isError ? 'is-blankstate' : 'is-hided'}>
        <SceneComponent antialias onSceneReady={this.onSceneReady} onRender={this.onRender} id="my-canvas" />

        {!mobile && debug && <div className="debug">

          <button onClick={() => this.clickBtModel(0)} className={`${this.state.currentModel === 0 ? "active" : ""}`}>{!assetUrl ? 'Tote' : 'assetUrl'}</button>

          <button onClick={() => this.clickBtModel(1)} className={`${this.state.currentModel === 1 ? "active" : ""}`}>Saddle</button>

          <button onClick={() => this.clickBtModel(2)} className={`${this.state.currentModel === 2 ? "active" : ""}`}>Vanity</button>

          <button onClick={() => this.clickBtModel(3)} className={`${this.state.currentModel === 3 ? "active" : ""}`}>Sneaker Dior Star</button>

          <button onClick={() => this.clickBtModel(4)} className={`${this.state.currentModel === 4 ? "active " : ""}`}>Porte-cartes</button>

          <button onClick={() => this.clickBtModel(5)} className={`${this.state.currentModel === 5 ? "active " : ""}secondary`}>Portefeuille (with lights)</button>

          <button onClick={() => this.clickBtModel(6)} className={`${this.state.currentModel === 6 ? "active " : ""}secondary`}>Car (sheen)</button>

          <button onClick={() => this.clickBtModel(7)} className={`${this.state.currentModel === 7 ? "active " : ""}secondary`}>Amber (IOR)</button>

          <button onClick={() => this.reset()}>Reset rotation</button>

          <div id="fps">0</div>
          <div className='debug-env'>
            <button onClick={() => this.addEnvironmentTexture(0)} className={`${this.state.currentEnv === 0 ? "active" : ""}`}>Env Studio</button>
            <button onClick={() => this.addEnvironmentTexture(1)} className={`${this.state.currentEnv === 1 ? "active" : ""}`}>Env Studio prod</button>
            <button onClick={() => this.addEnvironmentTexture(2)} className={`${this.state.currentEnv === 2 ? "active" : ""}`}>Env ScanRoom</button>
            <button onClick={() => this.addEnvironmentTexture(3)} className={`${this.state.currentEnv === 3 ? "active" : ""}`}>Env Holodeck</button>
            <button onClick={() => this.addEnvironmentTexture(4)} className={`${this.state.currentEnv === 4 ? "active" : ""}`}>Env Babylon Sandbox</button>
            <button onClick={() => this.removeEnvironmentTexture()} className={`${this.state.currentEnv === 5 ? "active" : ""}`}>No env</button>
          </div>
        </div>}

        <div className='infos'></div>
      </div>
    )
  }
};

export default App